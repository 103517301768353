<script>
import { SuawInputGroup, SuawHeading, SuawBlockQuote } from "@/components";
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";

export default {
  name: "OrganizerHandbook",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawBlockQuote,
    ResourceFrame
  }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
      <h1 class="suaw-organizer-resource-heading">Organizer <b>Handbook</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Since 2007, our organizers have run thousands of events using our tried-and-true formats. Now it's your turn!"
      />
      <p class="suaw-paragraph">
        If you'd like to become an organizer, follow our simple application process <router-link :to="{ name: 'BecomeAnOrganizer' }">here</router-link> to set up a video call with our team.
      </p>
      <p class="suaw-paragraph">
        Before your call with our team, it's helpful to familiarize yourself with our events. <b>We ask that you attend at least one Shut Up & Write! event before applying to host your own.</b> Once your application has been approved, follow these steps to launch your own Shut Up & Write! event.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">Getting Started</h2>

      <h2 class="suaw-organizer-resource-subtitle">1. Pick a format</h2>
      <p class="suaw-paragraph">Decide whether you would like to host your events in person or online.</p>
      <p class="suaw-paragraph">
        <b>In-person</b>: You'll need to pick a venue for your in-person event. Great places to host include your local coffee shop, library, or bookstore. Choosing a venue requires preparation, but for some writers, nothing beats the connection and productivity gained from meeting face-to-face on a regular basis.
      </p>
      <p class="suaw-paragraph">
        <b>Online:</b> Hosting an event online has a few advantages: it's incredibly convenient, can be done from anywhere with a good internet connection, and allows writers to participate who may otherwise not be able to attend in-person events. Hosting an online event requires the use of a paid account on a video conferencing platform that allows for a meeting to run 60+ minutes.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">2. Pick a time</h2>
      <p class="suaw-paragraph">
        Next, you'll need to pick a time for your event. Most organizers run Shut Up & Write! sessions once a week, but some like to run events even more frequently. Choose a recurring date and time that works with your schedule and is also accessible for other writers, such as mid-morning on Saturdays, or weekdays after work.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">3. Pick an event duration</h2>
      <p class="suaw-paragraph">
        How long are you going to write? A classic Shut Up & Write! is an hour of focused writing time, which is the duration we recommend for most organizers, especially those just starting out. An extended Shut Up & Write! is over an hour, but less than two hours. A Shut Up & Write! marathon is over two hours. If you'd like to write for a long period of time, remember to schedule breaks.
      </p>
      <p class="suaw-paragraph">What defines a Shut Up & Write! event? No read-alouds, no critiques, no feedback. Just writing.</p>
      
      <SuawBlockQuote
        text='The "Classic" Shut Up & Write: Start with 15 minutes of introductions. Then, set a timer for the duration of your event, start the timer, and tell everyone to "Shut Up & Write!" Use about 15 minutes at the end of the event to check in and share how your writing went. Then, offer an opportunity for everyone to stay and socialize.'
      />

      <h2 class="suaw-organizer-resource-subtitle">Sample Shut Up & Write! Event Schedule</h2>
      <div class="schedule-subsection">
        <p class="suaw-paragraph"><b>7:00 - Brief Introductions:</b></p>
        <ul class="suaw-organizer-resource-guide__list schedule-list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">The organizer welcomes everyone, then invites attendees to go around in a circle, give their names, and share what they'll be working on.</p>
          </li>
        </ul>
      </div>

      <div class="schedule-subsection">
        <p class="suaw-paragraph"><b>7:15 - Write for an Hour:</b></p>
        <ul class="suaw-organizer-resource-guide__list schedule-list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">The organizer sets a timer for an hour, then tells everyone to "Shut Up & Write!"</p>
          </li>
        </ul>
      </div>

      <div class="schedule-subsection">
        <p class="suaw-paragraph"><b>8:15 - Wrap Up with Optional Socializing:</b></p>
        <ul class="suaw-organizer-resource-guide__list schedule-list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Timer goes off to signify the conclusion of the event.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">Everyone goes around again and shares their feelings about their writing session and what they accomplished.</p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">The official event is now over, but people can stay and socialize for as long as they want.</p>
          </li>
        </ul>
      </div>

      <h2 class="suaw-organizer-resource-subtitle">4. Create your event pages and start hosting!</h2>
      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph"><router-link :to="{ name: 'ListingYourInPersonEvent' }">Listing your in-person event</router-link></p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph"><router-link :to="{ name: 'HostingYourInPersonEvent' }">Hosting your in-person event</router-link></p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph"><router-link :to="{ name: 'ListingYourOnlineEvent' }">Listing your online event</router-link></p>
          </li>
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph"><router-link :to="{ name: 'HostingYourOnlineEvent' }">Hosting your online event</router-link></p>
          </li>
        </ul>
      </div>
      <p class="suaw-paragraph">
        Contact <a href="mailto:organizers@shutupwrite.com">organizers@shutupwrite.com</a> and we'll help you set everything up.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">5. Spread the word</h2>
      <p class="suaw-paragraph">
        Now that you've created your event, other writers in our network will be able to find it! Additionally, we encourage you to promote your events on social media or leave flyers at your chosen venue. Here's where you can find resources to promote your event: <router-link :to="{ name: 'EventsToolkit' }">Events Toolkit</router-link>
      </p>

      <h2 class="suaw-organizer-resource-subtitle">Additional Tips</h2>

      <h2 class="suaw-organizer-resource-subtitle">Ask a Friend to Help</h2>
      <p class="suaw-paragraph">
        Having a co-host is a great way to have ongoing support, and we encourage this whenever possible. Try asking on social media or at the end of your event to find potential co-hosts. A co-host can serve as an emergency backup, be as involved as the main organizer, or strike a balance in between. If you identify a co-host, find an arrangement that works best for everyone. (Yes, you can have multiple co-hosts!)
      </p>

      <h2 class="suaw-organizer-resource-subtitle">Know the Code</h2>
      <p class="suaw-paragraph">
        Shut Up & Write! events are a space for accountable writing time. Be sure that you and your writers are following our <router-link :to="{ name: 'CodeOfConduct' }">Code of Conduct</router-link> at all events.
      </p>

      <h2 class="suaw-organizer-resource-subtitle">Keep Us Posted</h2>
      <p class="suaw-paragraph">
        Regularly check in with the Shut Up & Write! Community Team about your events, ideas you have about how to support your group, and any <a href="https://shutupwrite.typeform.com/to/EI3Ohubg" target="_blank">success stories</a> you'd like to share. Email us at <a href="mailto:organizers@shutupwrite.com">organizers@shutupwrite.com</a>.
      </p>

      <p class="suaw-paragraph">
        (P.S. Be sure to read the <router-link :to="{ name: 'OrganizerFaq' }">Organizer FAQ</router-link> as it covers a lot of helpful information you'll need to know as a host!)
      </p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}

.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}

.schedule-subsection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// .schedule-list {
//   margin-top: 0;
// }
</style>
